import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react';
import styled from 'styled-components';
import Text from '../text';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ForwardedRef<HTMLInputElement>;
  label?: string;
  error?: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  isIconOpaque?: boolean;
}

const TextFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextFieldLabel = styled.label`
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
`;

const TextFieldInput = styled.input<{ error: boolean; hasRightComponent?: boolean; isIconOpaque?: boolean }>`
  width: 100%;
  height: 52px;
  color: ${(props) => props.theme.color.textFieldTextColor};
  background-color: ${(props) => props.theme.color.textFieldBackgroundColor};
  border: 1px solid ${(props) => props.theme.color.textFieldBorderColor};
  padding: 12px 16px;
  border-radius: ${(props) => props.theme.rounding?.medium || '8px'};
  display: block;
  &::placeholder {
    color: ${(props) => props.theme.color.textFieldTextColorPlaceholder};
  }
  &:disabled {
    color: ${(props) => props.theme.color.textFieldTextColorDisabled};
    background-color: ${(props) => props.theme.color.textFieldBackgroundColorDisabled};
    border-color: ${(props) => props.theme.color.textFieldBorderColorDisabled};
    cursor: not-allowed;
    & + span {
      opacity: ${(props) => (props.isIconOpaque ? 1 : 0.4)};
    }
  }
  :focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  ${(props) =>
    props.error &&
    `
  border-color: ${props.theme.color.red50};
  &:focus {
    border-color: ${props.theme.color.red50};
  }
  `}
  ${(props) =>
    props.hasRightComponent &&
    `
  padding-right: 64px;
  `}
`;

const TextFieldErrorMessage = styled.div`
  color: ${(props) => props.theme.color.red50};
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 4px;
  p {
    margin-left: 4px;
  }
`;

const TextFieldInputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const TextFieldTextAppend = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: normal;
  user-select: none;
`;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, leftContent, rightContent, id, value, isIconOpaque, ...rest }: TextFieldProps, ref) => {
    const generatedId = useId();
    const inputId = id || generatedId;
    const fixedValue = value;

    const focusRef = () => {
      if (!ref) {
        return;
      }
      if (typeof ref === 'object') {
        const current = ref.current;
        if (!current) {
          return;
        }
        current.focus();
      }
    };

    return (
      <TextFieldWrapper>
        {label && <TextFieldLabel htmlFor={inputId}>{label}</TextFieldLabel>}
        <TextFieldInputWrapper>
          {leftContent}
          <TextFieldInput
            hasRightComponent={!!rightContent}
            id={inputId}
            ref={ref}
            value={fixedValue}
            {...rest}
            error={!!error}
            isIconOpaque={isIconOpaque}
          />
          {rightContent && <TextFieldTextAppend onClick={focusRef}>{rightContent}</TextFieldTextAppend>}
        </TextFieldInputWrapper>
        {error && (
          <TextFieldErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <Text size="s" weight="sb">
              {error}
            </Text>
          </TextFieldErrorMessage>
        )}
      </TextFieldWrapper>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
