import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, TextareaHTMLAttributes, useId } from 'react';
import styled from 'styled-components';
import Text from '../text';

const DEFAULT_MAX_CHARACTERS = 500;

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  ref?: ForwardedRef<HTMLTextAreaElement>;
  label?: string;
  error?: string;
}

const TextareaWrapper = styled.div`
  position: relative;
  > div {
    background-color: ${(props) => props.theme.color.textAreaBackgroundColor};
    border: 1px solid ${(props) => props.theme.color.textAreaBorderColor};
    border-radius: 8px;
    height: 80px;
    min-height: 136px;
  }
`;

const TextareaLabel = styled.label`
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
`;

const TextareaInput = styled.textarea<{ error: boolean }>`
  width: 100%;
  outline: none;
  height: 75%;
  color: ${(props) => props.theme.color.textAreaTextColor};
  background-color: ${(props) => props.theme.color.textAreaBackgroundColor};
  padding: 12px 16px;
  border-radius: 8px;
  display: block;
  resize: none;
  &::placeholder {
    color: ${(props) => props.theme.color.textAreaPlaceholderColor};
  }
  &:focus {
    border-color: ${(props) => props.theme.color.textAreaBorderColorFocus};
  }
  &:disabled {
    background-color: ${(props) => props.theme.color.textAreaBackgroundColorDisabled};
    border-color: ${(props) => props.theme.color.textAreaBorderColorDisabled};
    cursor: not-allowed;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  ${(props) =>
    props.error &&
    `
  border-color: ${props.theme.color.red50};
  &:focus {
    border-color: ${props.theme.color.red50};
  }
  `}
`;

const TextareaErrorMessage = styled.div`
  color: ${(props) => props.theme.color.red50};
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 4px;
  p {
    margin-left: 4px;
  }
`;

const CharactersLeftBox = styled.span`
  position: absolute;
  bottom: 5px;
  right: 15px;
  color: ${(props) => props.theme.color.secondaryTextColorPlaceholder};
`;

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ label, error, id, value, maxLength, ...rest }: TextareaProps, ref) => {
  const generatedId = useId();
  const textareaId = id || generatedId;
  const maxCharacters = maxLength || DEFAULT_MAX_CHARACTERS;
  const charactersLeftText = `${value?.toString().length}/${maxCharacters}`;

  return (
    <TextareaWrapper>
      {label && <TextareaLabel htmlFor={textareaId}>{label}</TextareaLabel>}
      <div>
        <TextareaInput id={textareaId} ref={ref} value={value} maxLength={maxCharacters} {...rest} error={!!error} />
        <CharactersLeftBox>{charactersLeftText}</CharactersLeftBox>
        {error && (
          <TextareaErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <Text size="s" weight="sb">
              {error}
            </Text>
          </TextareaErrorMessage>
        )}
      </div>
    </TextareaWrapper>
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
