import styled from 'styled-components';

const RadioWrapper = styled.label`
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-right: 30px;
  }
  > label {
    white-space: nowrap;
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
`;

const CustomRadioButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid ${(props) => props.theme.color.gray40};
  border-radius: 50%;
  display: block;
  position: relative;
  > span {
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
`;

const RadioInput = styled.input`
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  &:checked ~ div {
    background-color: ${(props) => props.theme.color.green50};
    border-color: ${(props) => props.theme.color.green50};
    span {
      display: block;
    }
  }

  &:disabled ~ div {
    background-color: ${(props) => props.theme.color.gray10};
  }
`;

interface RadioButtonProps {
  checked?: boolean;
  label: string;
  id: string;
  onChange: (value: string) => void;
  groupName: string;
  disabled?: boolean;
}

const RadioButton = ({ checked, id, label, onChange, groupName, disabled }: RadioButtonProps) => {
  const htmlIdentifier = `${groupName}_${id}`;
  return (
    <RadioButtonContainer>
      <RadioWrapper>
        <RadioInput type="radio" id={htmlIdentifier} checked={checked} disabled={disabled} onChange={() => onChange(id)} />
        <CustomRadioButton>
          <span />
        </CustomRadioButton>
      </RadioWrapper>
      <label htmlFor={htmlIdentifier}>{label}</label>
    </RadioButtonContainer>
  );
};

export default RadioButton;
