import styled from 'styled-components';

type Size = 's' | 'l';
type Weight = 'm' | 'sb';

interface TextProps {
  size?: Size;
  weight?: Weight;
}

const StyledText = styled.p<TextProps>`
  font-size: 16px;
  line-height: 28px;
  ${(props) => {
    switch (props.size) {
      case 'l': {
        return `
        font-size: 20px;
        line-height: 28px;
    `;
      }
      case 's': {
        return `
        font-size: 12px;
        line-height: 20px;
    `;
      }
    }
  }}
  ${(props) => {
    switch (props.weight) {
      case 'm': {
        return `
        font-weight: 500;
    `;
      }
      case 'sb': {
        return `
        font-weight: 600;
    `;
      }
    }
  }}
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  a,
  a:visited {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

function Text({ size, weight, tag, children, ...rest }: any) {
  return (
    <StyledText size={size} weight={weight} as={tag} {...rest}>
      {children}
    </StyledText>
  );
}

export default Text;
