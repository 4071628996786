import { InputHTMLAttributes, ReactNode, useId } from 'react';
import styled from 'styled-components';

const ToggleSwitchButtonContainer = styled.div`
  display: flex;
  > label:not(:first-of-type) {
    margin-left: 10px;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
    flex-direction: column-reverse;
    > label:not(:first-of-type) {
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
`;

const ToggleSwitchButtonWrapper = styled.label`
  width: 44px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const ToggleSwitchButtonInput = styled.input`
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  &:checked ~ span {
    background-color: ${(props) => props.theme.color.toggleSwitchButtonBackgroundColor};
  }
  &:checked ~ span:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;

const ToggleSwitchButtonSlider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 12px;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

interface ToggleSwitchButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  id?: string;
  children?: ReactNode;
}
const ToggleSwitchButton = ({ children, id, checked, ...rest }: ToggleSwitchButtonProps) => {
  const generatedId = useId();
  const inputId = id || generatedId;
  return (
    <ToggleSwitchButtonContainer>
      <ToggleSwitchButtonWrapper>
        <ToggleSwitchButtonInput id={inputId} checked={checked} type="checkbox" {...rest} />
        <ToggleSwitchButtonSlider />
      </ToggleSwitchButtonWrapper>
      {children && <label htmlFor={inputId}>{children.toString()}</label>}
    </ToggleSwitchButtonContainer>
  );
};

export default ToggleSwitchButton;
