import { ChangeEvent, useState } from 'react';
import { useRouter } from 'next/router';

import TextField from '../textField';

interface CurrencyFieldProps {
  defaultValue?: string;
  autoMaxValue?: boolean;
  rightContent?: any;
  onChange?(value?: number): void;
  value?: number | string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  maxValue?: number;
  autoFocus?: boolean;
}

function formatAsCurrency(value: number, locale: string = 'da-DK'): string {
  if (value == null) {
    return '';
  }
  if (value == 0) {
    return '0';
  }

  return (value / 100).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function CurrencyField(props: CurrencyFieldProps) {
  const {
    disabled,
    maxValue = Number.MAX_VALUE,
    autoMaxValue = false,
    placeholder,
    value,
    label,
    rightContent,
    defaultValue,
    onChange,
    autoFocus,
  } = props;
  const { locale } = useRouter();
  const currencyValue = formatAsCurrency(Number(value), locale);
  const [textfieldValue, setTextFieldValue] = useState<string>(currencyValue);

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const formattedValue = value.replace(/\./g, '').replace(',', '.');
    const valueAsNumber = Number(formattedValue);

    // regex checks if there are more than 2 decimal places and max 8 digits before the decimal point
    if (isNaN(valueAsNumber) || /^\d+\.\d{3,}$/.test(formattedValue) || /^\d{8,}(\.\d+)?$/.test(formattedValue)) {
      return;
    }

    setTextFieldValue((prev) => {
      if (valueAsNumber > maxValue) {
        return autoMaxValue ? maxValue.toString() : prev;
      }

      return value.endsWith(' ') ? value.trimEnd() : value;
    });

    if (!onChange) {
      return;
    }

    onChange(Math.round(valueAsNumber * 100));
  }

  return (
    <TextField
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      disabled={disabled}
      isIconOpaque={true}
      label={label}
      placeholder={placeholder}
      rightContent={rightContent}
      value={textfieldValue}
      onChange={(event) => handleOnChange(event)}
    />
  );
}

export default CurrencyField;
