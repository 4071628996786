import styled from 'styled-components';

type Color = 'green' | 'orange' | 'red';

interface PillProps {
  children: string;
  color?: Color;
}

const PillWrapper = styled.div<PillProps>`
  display: inline-block;
  padding: 4px 16px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  background: ${(props) => props.theme.color.gray20};
  color: ${(props) => props.theme.color.gray70};
  ${(props) => {
    switch (props.color) {
      case 'green': {
        return `
        background: ${props.theme.color.availablePillBackgroundColor};
        color: ${props.theme.color.availablePillTextColor};
    `;
      }
      case 'orange': {
        return `
        background: ${props.theme.color.reservedPillBackgroundColor};
        color: ${props.theme.color.reservedPillTextColor};
    `;
      }
      case 'red': {
        return `
        background: ${props.theme.color.rentedPillBackgroundColor};
        color: ${props.theme.color.rentedPillTextColor};
    `;
      }
    }
  }}
`;

function Pill({ children, color }: PillProps) {
  return <PillWrapper color={color}>{children}</PillWrapper>;
}

export default Pill;
