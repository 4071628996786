import { forwardRef } from 'react';
import styled from 'styled-components';
import countries from './country_codes_and_phone_codes';
import TextField, { TextFieldProps } from '../textField';

const DEFAULT_PHONE_CODE = '45';
const DEFAULT_PHONE_CODE_LENGTH = 8;
const VALIDATION_PATTERN = /[^\d\+\s]+/;

function splitPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber?.length) {
    return {
      phoneCode: DEFAULT_PHONE_CODE,
      localPhoneNumber: '',
    };
  }
  const [first, ...rest] = phoneNumber.split(' ');
  const [phoneCode, localPhoneNumber] = [first, rest.join(' ')];
  return {
    phoneCode: phoneCode.replace('+', ''),
    localPhoneNumber,
  };
}

function combinePhoneNumber(phoneCode: string, phoneNumber: string) {
  return `+${phoneCode} ${phoneNumber}`;
}

const TextFieldCountryCode = styled.div<{ disabled?: boolean }>`
  flex: 0 0 auto;
  position: relative;
  height: 52px;
  > div {
    height: 100%;
    color: ${(props) => (props?.disabled ? props.theme.color.textFieldTextColorDisabled : props.theme.color.textFieldTextColor)};
    border: 1px solid
      ${(props) => (props?.disabled ? props.theme.color.textFieldBorderColorDisabled : props.theme.color.textFieldBorderColor)};
    border-right: 1px solid ${(props) => (props?.disabled ? props.theme.color.textFieldBorderColorDisabled : props.theme.color.textAreaBorderColor)};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 11px 16px;
  }
  > select {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  & + input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const PhoneCodeDisplay = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    `background-color: ${props.theme.color.textAreaBackgroundColorDisabled};
    cursor: not-allowed;
  `}
`;

interface PhoneInputFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (phoneNumber: string) => void;
}

const calculateMaxLength = (phoneCode: string, maxLength?: number) => {
  if (!maxLength || phoneCode === DEFAULT_PHONE_CODE) {
    return DEFAULT_PHONE_CODE_LENGTH;
  }

  return maxLength;
};

const PhoneInputField = forwardRef<HTMLInputElement, PhoneInputFieldProps>(({ value, onChange, disabled, maxLength, ...props }, ref) => {
  const { localPhoneNumber, phoneCode } = splitPhoneNumber(String(value));
  const calculatedMaxLength = calculateMaxLength(phoneCode, maxLength);
  return (
    <TextField
      {...props}
      value={localPhoneNumber}
      onChange={(event) => {
        const { value } = event.target;
        const inputValueWithoutSpaces = value.replace(/\s/g, '');
        const isValid = !VALIDATION_PATTERN.test(inputValueWithoutSpaces);
        const overMaxLength = inputValueWithoutSpaces.length > calculatedMaxLength;
        const wasRemoved = value.length < localPhoneNumber.length;
        if (!isValid) {
          return;
        }
        //In case a user switches to a phone code with a shorter maximum phone number length, he should still be able to remove digits.
        if (overMaxLength && !wasRemoved) {
          return;
        }
        const valueWithoutMultipleSpaces = value.replace(/\s{2,}/g, ' ');
        const combinedValue = combinePhoneNumber(phoneCode, valueWithoutMultipleSpaces);
        onChange(combinedValue);
      }}
      disabled={disabled}
      ref={ref}
      leftContent={
        <TextFieldCountryCode disabled={disabled}>
          <PhoneCodeDisplay disabled={disabled}>+{phoneCode}</PhoneCodeDisplay>
          {!disabled && (
            <select
              autoComplete="tel-country-code"
              value={phoneCode}
              onChange={(event) => {
                const { value } = event.target;
                const combinedValue = combinePhoneNumber(value, localPhoneNumber);
                onChange(combinedValue);
              }}
            >
              {countries.map((country, index) => (
                <option key={index} value={country.phoneCode}>
                  {`${country.countryName} (+${country.phoneCode})`}
                </option>
              ))}
            </select>
          )}
        </TextFieldCountryCode>
      }
    />
  );
});

PhoneInputField.displayName = 'PhoneInputField';

export default PhoneInputField;
