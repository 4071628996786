import styled from 'styled-components';

type Size = 'xxs' | 'xs' | 's' | 'r' | 'l' | 'xl' | 'xxl';

const StyledHeading = styled.p<{ size?: Size }>`
  ${(props) => {
    switch (props.size) {
      case 'xxl': {
        return `
        font-size: 80px;
        line-height: 100px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 68px;
          line-height: 80px;
        }
    `;
      }
      case 'xl': {
        return `
        font-size: 68px;
        line-height: 80px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 56px;
          line-height: 68px;
        }
    `;
      }
      case 'l': {
        return `
        font-size: 56px;
        line-height: 68px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 44px;
          line-height: 56px;
        }
    `;
      }
      case 'r': {
        return `
        font-size: 44px;
        line-height: 56px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 32px;
          line-height: 44px;
        }
    `;
      }
      case 's': {
        return `
        font-size: 32px;
        line-height: 44px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 24px;
          line-height: 32px;
        }
    `;
      }
      case 'xs': {
        return `
        font-size: 24px;
        line-height: 32px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 20px;
          line-height: 28px;
        }
    `;
      }
      case 'xxs': {
        return `
        font-size: 20px;
        line-height: 28px;
        @media only screen and (max-width: ${props.theme.breakpoint.md}) {
          font-size: 16px;
        }
    `;
      }
    }
  }}
  font-weight: 600;
`;

interface HeadingProps {
  size?: Size;
  tag?: any;
  children: any;
}

function Heading({ size, tag, children }: HeadingProps) {
  return (
    <StyledHeading size={size} as={tag}>
      {children}
    </StyledHeading>
  );
}

export default Heading;
