import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 1172px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
`;

function Container({ children }: any) {
  return <StyledContainer>{children}</StyledContainer>;
}

export default Container;
