import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';
import { useRouter } from 'next/router';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import TextField from '../textField';

registerLocale('da-DK', daLocale);
registerLocale('en-DK', enLocale);

const DatePickerWrapper = styled.div`
  .react-datepicker {
    font-family: 'Poppins', sans-serif;
    color: ${(props) => props.theme.color.datepickerTextColor};
    background-color: ${(props) => props.theme.color.datepickerBackgroundColor};
    border-color: ${(props) => props.theme.color.datepickerHeaderBorderColor};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  .react-datepicker__header {
    background-color: ${(props) => props.theme.color.datepickerHeaderBackgroundColor};
    border-color: ${(props) => props.theme.color.datepickerHeaderBorderColor};
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name {
    color: ${(props) => props.theme.color.datepickerHeaderTextColor};
  }
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${(props) => props.theme.color.datepickerTextColor};
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    transition: ${(props) => props.theme.style.primaryButtonTransition};
    background-color: ${(props) => props.theme.color.datepickerBackgroundColorHover};
    color: ${(props) => props.theme.color.datepickerTextColorHover};
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: ${(props) => props.theme.color.datepickerTextColorDisabled};
  }
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
    color: ${(props) => props.theme.color.datepickerTextColorDisabled};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected {
    transition: ${(props) => props.theme.style.primaryButtonTransition};
    background-color: ${(props) => props.theme.color.datepickerSelectedBackgroundColor};
    color: ${(props) => props.theme.color.datepickerSelectedTextColor};

    :hover {
      background-color: ${(props) => props.theme.color.datepickerSelectedBackgroundColorHover};
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__close-icon {
    right: 32px;
    &:after {
      background-color: ${(props) => props.theme.color.datepickerSelectedBackgroundColor};
    }
  }
  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 0 10px;
    appearance: auto;
    border: 1px solid ${(props) => props.theme.color.datepickerBorderColor};
    border-radius: 4px;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    margin: 0 5px;
  }
`;

const DatePicker = (props: ReactDatePickerProps) => {
  const { locale } = useRouter();
  return (
    <DatePickerWrapper>
      <ReactDatePicker
        calendarStartDay={1}
        popperPlacement="bottom-end"
        locale={locale}
        customInput={<TextField rightContent={<FontAwesomeIcon icon={faCalendar} />} />}
        autoComplete="off"
        {...props}
      />
    </DatePickerWrapper>
  );
};

export default DatePicker;
