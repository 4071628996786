import { faCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'markdown-to-jsx';
import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react';
import styled from 'styled-components';
import Text from '../text';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  ref?: ForwardedRef<HTMLInputElement>;
  label?: string;
  error?: string;
}

const CheckboxContent = styled.div`
  position: relative;
  padding-left: 8px;
  a {
    text-decoration: underline;
  }
`;

const CheckboxWrapper = styled.label`
  width: 24px;
  margin-top: 5px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const CheckboxIcon = styled.span`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.color.checkBoxBackgroundColor};
  border: 1px solid ${(props) => props.theme.color.checkBoxBorderColor};
  border-radius: 4px;
  display: block;
  position: relative;
  svg {
    height: 14px;
    color: ${(props) => props.theme.color.checkBoxTextColorActive};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
`;

const CheckboxInput = styled.input`
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  &:focus ~ span {
    border-color: ${(props) => props.theme.color.checkBoxBorderColorFocus};
  }
  &:checked ~ span {
    background-color: ${(props) => props.theme.color.checkBoxBackgroundColorActive};
    border-color: ${(props) => props.theme.color.checkBoxBorderColorActive};
    svg {
      display: block;
    }
  }
  &:disabled ~ span {
    background-color: ${(props) => props.theme.color.checkBoxBackgroundColorDisabled};
    border-color: ${(props) => props.theme.color.checkBoxBorderColorDisabled};
    cursor: not-allowed;
    svg {
      color: ${(props) => props.theme.color.checkBoxTextColorDisabled};
    }
  }
  &:disabled {
    &:checked ~ span {
      background-color: ${(props) => props.theme.color.checkBoxBackgroundColorDisabled};
      border-color: ${(props) => props.theme.color.checkBoxBackgroundColorDisabled};
      cursor: not-allowed;
      svg {
        color: ${(props) => props.theme.color.checkBoxTextColorDisabled};
      }
    }
  }
`;

const CheckboxOuter = styled.div`
  display: block;
`;

const CheckboxErrorMessage = styled.div`
  color: ${(props) => props.theme.color.red50};
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 4px;
  p {
    margin-left: 4px;
  }
`;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ children, label, error, id, ...rest }: CheckboxProps, ref) => {
  const generatedId = useId();
  const inputId = id || generatedId;
  if (children) {
    return (
      <CheckboxOuter>
        <div className="flex items-start">
          <CheckboxWrapper>
            <CheckboxInput id={inputId} type="checkbox" ref={ref} {...rest} />
            <CheckboxIcon>
              <FontAwesomeIcon icon={faCheck} />
            </CheckboxIcon>
          </CheckboxWrapper>
          <CheckboxContent>
            <label htmlFor={inputId}>
              {typeof children === 'string' || (Array.isArray(children) && children.length === 1 && typeof children[0] === 'string') ? (
                <Markdown
                  options={{
                    overrides: {
                      p: Text,
                      a: {
                        component: 'a',
                        props: {
                          rel: 'noopener noreferrer',
                          target: '_blank',
                        },
                      },
                    },
                  }}
                >
                  {typeof children === 'string' ? children : children[0]}
                </Markdown>
              ) : (
                children
              )}
            </label>
          </CheckboxContent>
        </div>
        {error && (
          <CheckboxErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <Text size="s" weight="sb">
              {error}
            </Text>
          </CheckboxErrorMessage>
        )}
      </CheckboxOuter>
    );
  }
  return (
    <CheckboxWrapper>
      <CheckboxInput id={inputId} type="checkbox" ref={ref} {...rest} />
      <CheckboxIcon>
        <FontAwesomeIcon icon={faCheck} />
      </CheckboxIcon>
    </CheckboxWrapper>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
