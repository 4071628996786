import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Container from '../container';
import { ModalClose, ModalContent, ModalOverlay } from './styled';

export const ModalHeader = styled.div`
  margin-bottom: 32px;
  h3 {
    margin-bottom: 16px;
  }
`;

export const ModalText = styled.div`
  margin-bottom: 32px;
  p {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ModalTextField = styled.div`
  margin-bottom: 24px;
  h3 {
    margin-bottom: 4px;
  }
`;

export const ModalButtons = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const ModalCheckbox = styled.div`
  margin-bottom: 32px;
`;

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  closeModal: () => void;
  isCloseButtonVisible?: boolean;
}

const Modal = ({ children, isOpen, closeModal, isCloseButtonVisible = true }: ModalProps) => {
  return (
    <ReactModal
      bodyOpenClassName={'overflow-hidden'}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="_"
      overlayElement={(props, contentElement) => <ModalOverlay {...props}>{contentElement}</ModalOverlay>}
      contentElement={(props, children) => (
        <Container>
          <ModalContent {...props}>{children}</ModalContent>
        </Container>
      )}
      appElement={[]}
    >
      {isCloseButtonVisible && (
        <ModalClose onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </ModalClose>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
