import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  ref?: ForwardedRef<HTMLSelectElement>;
  children: any;
  placeHolder?: boolean;
  label?: string;
}

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectInput = styled.select<SelectProps>`
  width: 100%;
  height: 52px;
  color: ${(props) => (props.placeHolder ? props.theme.color.textFieldTextColor : 'inherit')};
  background-color: white;
  border: 1px solid ${(props) => props.theme.color.textFieldBorderColor};
  padding: 12px 16px;
  border-radius: 8px;
  display: block;
  ${(props) => {
    if (props.placeHolder) {
      return `
        option {
          color: black;
        }
      `;
    }
  }}
`;

const SelectIcon = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
  pointer-events: none;
  svg {
    height: 12px;
  }
`;

const Select = forwardRef<HTMLSelectElement, SelectProps>(({ children, placeHolder, label, ...rest }: SelectProps, ref) => {
  return (
    <>
      <label className="font-semibold mb-1">{label}</label>
      <SelectWrapper>
        <SelectInput placeHolder={placeHolder} ref={ref} {...rest}>
          {children}
        </SelectInput>
        <SelectIcon>
          <FontAwesomeIcon icon={faSort} />
        </SelectIcon>
      </SelectWrapper>
    </>
  );
});

Select.displayName = 'Select';

export default Select;
