import styled from 'styled-components';

type Direction = 'up' | 'down';
type Position = 'mobileCenter';

interface PopoverMenuProps {
  children: any;
  position?: Position;
  direction?: Direction;
  isScrollable?: boolean;
}

const StyledPopoverMenu = styled.ul<PopoverMenuProps>`
  min-width: 248px;
  max-width: 300px;
  background: ${(props) => props.theme.color.popoverMenuBackgroundColor};
  text-align: left;
  color: ${(props) => props.theme.color.popoverMenuItemTextColor};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px 0;
  position: absolute;
  ${(props) => {
    if (props.direction === 'up') {
      return `bottom: calc(100% + 8px);`;
    }
    return `top: calc(100% + 8px);`;
  }}
  right: 0;
  z-index: 10;

  ${(props) => {
    if (props.position === 'mobileCenter') {
      return `
      @media only screen and (max-width: ${props.theme.breakpoint.lg}) {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
    `;
    }
  }}
  li {
    a {
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 8px 20px;
      cursor: pointer;
      transition: ${(props) => props.theme.style.menuItemTransition};
      &:hover {
        color: ${(props) => props.theme.color.popoverMenuItemTextColorHover};
        background: ${(props) => props.theme.color.popoverMenuItemBackgroundColorHover};
      }
      &:active {
        background: ${(props) => props.theme.color.popoverMenuItemBackgroundColorActive};
      }
      > span {
        flex: 0 0 24px;
      }
      > span + p {
        margin-left: 8px;
      }
      > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const PopoverMenuMobile = styled.li`
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: none;
  }
`;

export const PopoverMenuSeparator = styled.li`
  height: 1px;
  margin: 12px 0;
  background: ${(props) => props.theme.color.gray30};
`;

interface PopoverMenuItemProps {
  isScrollable?: boolean;
}

export const PopoverMenuScroll = styled.div<PopoverMenuItemProps>`
  max-height: ${(props) => (props.isScrollable !== false ? '132px' : 'none')};
  overflow-x: hidden;
  overflow-y: auto;
`;

function PopoverMenu({ children, position, direction }: PopoverMenuProps) {
  return (
    <StyledPopoverMenu direction={direction} position={position}>
      {children}
    </StyledPopoverMenu>
  );
}

export default PopoverMenu;
