import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

interface IconProps {
  leftIcon?: IconProp;
  rightIcon?: IconProp;
}
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, IconProps {
  small?: boolean;
  fullWidth?: boolean;
  color?: string;
  ref?: ForwardedRef<HTMLButtonElement>;
  children?: ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  width: auto;
  height: 52px;
  color: ${(props) => props.theme.color.secondaryButtonTextColor};
  background: ${(props) => props.theme.color.secondaryButtonBackgroundColor};
  border: 1px solid ${(props) => props.theme.color.secondaryButtonBorderColor};
  padding: 12px 20px;
  font-weight: 600;
  border-radius: ${(props) => props.theme.cornerRadius.medium};
  display: inline-block;
  cursor: pointer;
  transition: ${(props) => props.theme.style.secondaryButtonTransition};
  &:hover {
    background: ${(props) => props.theme.color.secondaryButtonBackgroundColorHover};
    border-color: ${(props) => props.theme.color.secondaryButtonBorderColorHover};
    color: ${(props) => props.theme.color.secondaryButtonTextColorHover};
  }
  &:active {
    background: ${(props) => props.theme.color.secondaryButtonBackgroundColorActive};
    border-color: ${(props) => props.theme.color.secondaryButtonBorderColorActive};
    color: ${(props) => props.theme.color.secondaryButtonTextColorActive};
  }
  &:disabled {
    color: ${(props) => props.theme.color.secondaryButtonTextColorDisabled};
    border-color: ${(props) => props.theme.color.secondaryButtonBorderColorDisabled};
    background-color: ${(props) => props.theme.color.secondaryButtonBackgroundColorDisabled};
    cursor: not-allowed;
  }
  svg {
    width: 16px;
    &.leftIcon {
      margin-right: 8px;
    }
    &.rightIcon {
      margin-left: 8px;
    }
  }
  i {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 8px;
  }
  ${(props) => {
    if (props.small) {
      return `
        height: 36px;
        font-size: 12px;
        line-height: 20px;
        padding: 8px 16px;
        border-radius: 4px;
        svg {
          &.leftIcon {
            margin-right: 4px;
          }
          &.rightIcon {
            margin-left: 4px;
          }
        }
      `;
    }
  }}
  ${(props) => {
    if (props.fullWidth) {
      return `
        width: 100%;
      `;
    }
  }}
`;

const PrimaryButton = styled(StyledButton)`
  color: ${(props) => props.theme.color.primaryButtonTextColor};
  background: ${(props) => props.theme.color.primaryButtonBackgroundColor};
  border-color: ${(props) => props.theme.color.primaryButtonBorderColor};
  transition: ${(props) => props.theme.style.primaryButtonTransition};
  &:hover {
    color: ${(props) => props.theme.color.primaryButtonTextColorHover};
    background: ${(props) => props.theme.color.primaryButtonBackgroundColorHover};
    border-color: ${(props) => props.theme.color.primaryButtonBorderColorHover};
  }
  &:active {
    color: ${(props) => props.theme.color.primaryButtonTextColorActive};
    background: ${(props) => props.theme.color.primaryButtonBackgroundColorActive};
    border-color: ${(props) => props.theme.color.primaryButtonBorderColorActive};
  }
  &:disabled {
    color: ${(props) => props.theme.color.primaryButtonTextColorDisabled};
    background: ${(props) => props.theme.color.primaryButtonBackgroundColorDisabled};
    border-color: ${(props) => props.theme.color.primaryButtonBorderColorDisabled};
  }
`;

const DangerButton = styled(StyledButton)`
  color: white;
  background: ${(props) => props.theme.color.red50};
  border-color: ${(props) => props.theme.color.red50};
  &:hover {
    background: ${(props) => props.theme.color.red40};
    border-color: ${(props) => props.theme.color.red40};
  }
  &:active {
    background: ${(props) => props.theme.color.red60};
    border-color: ${(props) => props.theme.color.red60};
  }
  &:disabled {
    color: ${(props) => props.theme.color.gray20};
    background: ${(props) => props.theme.color.gray50};
    border-color: ${(props) => props.theme.color.gray50};
  }
`;

const WhiteButton = styled(StyledButton)`
  color: white;
  border-color: white;
  &:hover {
    border-color: rgba(255, 255, 255, 0.8);
  }
  &:active {
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    border-color: rgba(255, 255, 255, 0.6);
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4);
  }
`;

const ButtonContentWithIcons = ({ leftIcon, rightIcon, children }: IconProps & { children: ReactNode }) => (
  <>
    {leftIcon && <FontAwesomeIcon icon={leftIcon} className="leftIcon" />}
    {children}
    {rightIcon && <FontAwesomeIcon icon={rightIcon} className="rightIcon" />}
  </>
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ color, children, leftIcon, rightIcon, ...rest }: ButtonProps, ref) => {
  const buttonProps: ButtonProps = {
    color,
    ref,
    ...rest,
  };
  if (color === 'primary') {
    return (
      <PrimaryButton {...buttonProps}>
        <ButtonContentWithIcons leftIcon={leftIcon} rightIcon={rightIcon}>
          {children}
        </ButtonContentWithIcons>
      </PrimaryButton>
    );
  }
  if (color === 'danger') {
    return (
      <DangerButton {...buttonProps}>
        <ButtonContentWithIcons leftIcon={leftIcon} rightIcon={rightIcon}>
          {children}
        </ButtonContentWithIcons>
      </DangerButton>
    );
  }
  if (color === 'white') {
    return (
      <WhiteButton {...buttonProps}>
        <ButtonContentWithIcons leftIcon={leftIcon} rightIcon={rightIcon}>
          {children}
        </ButtonContentWithIcons>
      </WhiteButton>
    );
  }
  return (
    <StyledButton {...buttonProps}>
      <ButtonContentWithIcons leftIcon={leftIcon} rightIcon={rightIcon}>
        {children}
      </ButtonContentWithIcons>
    </StyledButton>
  );
});

Button.displayName = 'Button';

export default Button;
