import styled from 'styled-components';
import RadioButton from '../radioButton';

const RadioButtonGroupWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
    flex-direction: column;
    > div {
      margin-bottom: 5px;
    }
  }
`;

interface RadioButtonEntry {
  id: string;
  label: string;
}

interface RadioButtonGroupProps {
  entries: RadioButtonEntry[];
  value: string;
  onChange: (value: string) => void;
  groupName: string;
}

const RadioButtonGroup = ({ entries, value, onChange, groupName }: RadioButtonGroupProps) => {
  return (
    <RadioButtonGroupWrapper>
      {entries.map((entry) => (
        <RadioButton id={entry.id} label={entry.label} key={entry.id} checked={value === entry.id} onChange={onChange} groupName={groupName} />
      ))}
    </RadioButtonGroupWrapper>
  );
};

export default RadioButtonGroup;
